import React from 'react'
import ReactPaginate from 'react-paginate'
import './../styles/ReactPaginate.css'

interface PaginationProps {
  pageCount: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({ pageCount, onPageChange }: PaginationProps) => {
  return (
    <ReactPaginate
      className={`pagination`}
      nextLabel=">"
      onPageChange={({ selected }) => {
        onPageChange(selected)
      }}
      pageCount={pageCount}
      previousLabel="<"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      activeClassName="active"
    />
  )
}

export default Pagination

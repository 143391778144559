import React from 'react'
import LayoutRoot from '../components/LayoutRoot'
import ArticlesComponent from '../components/Articles'
import * as globalStyles from './../styles/Global.module.scss'
import Pagination from '../components/Pagination'

interface ArticletypeProps {
  pageContext: {
    typeName: string
    articles: Article[]
  }
}

const Articletype: React.FC<ArticletypeProps> = ({ pageContext }) => {
  const perPage = 10
  const articles = pageContext.articles
  const [page, setPage] = React.useState(0)
  const pageCount = Math.ceil(articles.length / perPage)

  return (
    <LayoutRoot>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <div className={globalStyles.pageContentTitle}>{pageContext.typeName}</div>
          <ArticlesComponent articles={articles.slice(page * perPage, (page + 1) * perPage)} />
          {pageCount > 1 && <Pagination onPageChange={setPage} pageCount={pageCount} />}
        </div>
      </div>
    </LayoutRoot>
  )
}

export default Articletype
